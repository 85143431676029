import { useEffect } from "react";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import MetaHeader from "@/components/layout/MetaHeader";
import Header from "@/components/layout/Header";
import Menu from "@/components/layout/Menu";
import PageNotFound from "@/components/PageNotFound";
import SlugMainComp from "@/components/SlugMainComp";
import Footer from "@/components/layout/Footer";
import { getProps } from "@/cms/strapi";
import { useAuthValue } from "@/contexts/contextAuth";
import HomeNonStrapiPage from "@/components/home/HomeNonStrapiPage";
import useApi from "@/hooks/useApi";

const COLLECTION: string = "base-pages";
const PREFIX: string = "";

interface Props {
  content: any;
  headers: any;
  banner?: any;
}

const Home = ({ content, headers, banner }: Props) => {
  const router = useRouter();
  const { account } = router.query;

  const {
    setIsSignin,
    setIsSignup,
    setIsResetPassword,
    setType,
    setMessage,
    setIsShow,
    setIsLoading,
  } = useAuthValue();

  const { validateRetPasswordToken } = useApi();

  let data = null;

  if (Array.isArray(content?.attributes?.Content)) {
    content.attributes.Content.map((item: any) => {
      if (item.__component == "app-forms.vehicle-search") {
        data = item;
      }
    });
  }

  const onValidateResetPasswordLink = async () => {
    try {
      setIsLoading(true);
      await validateRetPasswordToken(router.query.token as string);
      setIsResetPassword(true);
    } catch {
      setType("error");
      setMessage("Invalid reset password link");
      setIsShow(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (account == "create") {
      setIsSignup(true);
    } else if (account == "login") {
      setIsSignin(true);
    } else if (account === "reset") {
      onValidateResetPasswordLink();
    }
  }, [router.query]);

  if (content != null) {
    return (
      <div className="w-full">
        <MetaHeader content={content?.attributes || {}} />
        <Header snippets={headers?.header} banner={banner} />
        <Menu />
        <HomeNonStrapiPage data={data} />
        <SlugMainComp data={content} />
        <Footer snippets={headers.bodyBottom} />
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <Menu />
        <Header />
        <PageNotFound />
        <Footer />
      </div>
    );
  }
};

export const getStaticProps: GetStaticProps<Props> = async (
  context
): Promise<{ props: Props; revalidate: number | boolean }> =>
  getProps(context, COLLECTION, PREFIX).then((p) => ({
    props: { ...p.props },
    revalidate: p.revalidate,
  }));

export default Home;
