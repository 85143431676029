import Image from "next/image";
import LicensePlateAndVinSection from "@/components/sell/LicensePlateAndVinSection";
import DealStartNowSection from "@/components/dealnow/DealStartNowSection";
import VerifiedIcon from "@/components/Icon/VerifiedIcon";
import VehicleSearch from "@/components/VehicleSearch";
import {
  ChangeEvent,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import GoogleReviewsScript from "../scripts/GoogleReviews";

type Props = {
  data: any;
};

const HomeNonStrapiPage = ({ data }: Props) => {
  const [searchKey, setSearchKey] = useState<string>("");

  const openGoogleReviewsWidget = () => {
    const targetElement = document.querySelector(
      ".es-google-icon"
    ) as HTMLElement;

    if (targetElement) {
      targetElement.click();
    }
  };

  const router = useRouter();
  const searchInput = useRef(null);

  const handleSearchInputKeyUp: KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };
  const handleSearchClick = () => {
    router.push(`/buy?searchKey=${searchKey}`);
  };

  const handleSearchKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  return (
    <>
      <div className="w-full">
        <section>
          <div className="relative w-full flex items-center justify-center">
            <div
              className={`w-full  bg-[#45577D] bg-opacity-100 relative justify-center sm:h-[665px] h-[850px] `}
            >
              <div className="absolute top-0 left-0 w-full sm:h-[665px] h-[850px] bg-[#26364F] opacity-[84%] z-10"></div>
              <Image
                width={1200}
                height={436}
                src="/assets/dealBradAndCaleb.svg"
                className={`hidden sm:block w-full sm:h-[665px] h-[850px] opacity-90 object-cover z-0`}
                alt="buy your next vehicle"
              />
              <Image
                width={1060}
                height={850}
                src="/assets/dealBradAndCaleb.svg"
                className="sm:hidden w-full h-[850px] opacity-90 object-cover"
                alt="buy your next vehicle"
              />
            </div>

            <div
              className={`absolute w-full flex sm:flex-row flex-col items-center justify-center z-20  mx-auto p-6`}
            >
              <div className="max-w-[440px] sm:mb-[100px]">
                <div
                  className={`text-white font-[700] sm:text-[54px] leading-[1] text-[30px] sm:text-left text-center`}
                >
                  Private sales{" "}
                  <div className="text-[#00B3DE]">made simple</div>
                </div>
                <div className="pt-4 text-white  sm:text-[24px] text-[18px]  sm:text-left text-center">
                  The only end-to-end transactional marketplace for
                  private-party vehicles
                </div>
                <div className="flex sm:flex-row flex-col pt-8">
                  <Link href={`/sell`}>
                    <button
                      className="btn-primary sm:w-[160px] w-full"
                      onClick={() => {}}
                    >
                      List a vehicle
                    </button>
                  </Link>

                  <Link href={`/buy`}>
                    <button
                      className="btn-outline-primary flex sm:ml-6 sm:mt-0 mt-4 w-full items-center text-black border-white justify-center"
                      onClick={() => {}}
                    >
                      <span className="pr-2">
                        <Image
                          width={15}
                          height={15}
                          src="/assets/search.svg"
                          className="w-full min-h-[15px] max-h-[15px]"
                          alt="search"
                        />
                      </span>
                      Browse vehicles
                    </button>
                  </Link>
                </div>
              </div>
              <div className="sm:pt-5 pt-10">
                <Image
                  width={400}
                  height={600}
                  src="/assets/landingPageImg.svg"
                  className="w-[400px] h-full"
                  alt="search"
                />
              </div>{" "}
            </div>
            <div
              className="absolute z-20 bottom-5 cursor-pointer"
              onClick={() => {
                openGoogleReviewsWidget();
              }}
            >
              <Image
                width={300}
                height={50}
                src="/assets/FiveStarRatings1.svg"
                className="w-[200px] h-full"
                alt="search"
              />
            </div>
          </div>
        </section>
        <section className="w-full h-full bg-[#F7F7F7] flex flex-col-reverse md:flex-row justify-center items-start px-[25px] md:px-[90px] py-[60px]">
          <Image
            height={338}
            width={504}
            src="/assets/large-collection.png"
            alt="collection"
          />
          <div className="sm:text-[22px] font-bold pt-[15px] md:pl-[30px] md:w-[508px] text-[#333]">
            <h1 className="text-[34px] md:text-[45px] leading-[60px]">
              Largest selection of private-party cars
            </h1>
            <h3 className="flex pt-[25px] items-center space-x-2">
              <VerifiedIcon color="#4DCBAB" bordercolor="#4DCBAB" />
              <p>Over 45,000 Listings</p>
            </h3>
            <h3 className="flex py-[18px] items-center space-x-2">
              <VerifiedIcon color="#4DCBAB" bordercolor="#4DCBAB" />
              <p>
                Safely transact using{" "}
                <Link className="hover:underline" href={`/dealnow`}>
                  DealNow
                </Link>
              </p>
            </h3>
            <Link href={`/buy`}>
              <button
                className="btn-primary py-[15px] sm:w-[160px] mb-[15px] mt-[10px]"
                onClick={() => {}}
              >
                Browse all vehicles
              </button>
            </Link>
          </div>
        </section>

        <section className="w-full h-full bg-[#FFFFFF] md:flex justify-center items-start md:space-x-12 pb-[60px]">
          <div className="w-[90%] xs:w-[300px] sm:w-[436px] text-[#333] text-base sm:text-[22px] font-bold pt-[60px] sm:pt-[130px] mx-auto md:mx-0">
            <h2 className="text-[34px] sm:text-[50px] font-bold">
              Sell your vehicle
            </h2>
            <p className="text-black text-base sm:text-xl font-normal pt-6">
              Take the hassle out of private car sales
            </p>
            <div className="w-full space-y-4 pt-5">
              <div className="flex items-center space-x-2">
                <VerifiedIcon color="#049BC0" bordercolor="#DBF4FA" />
                <p>ID verification</p>
              </div>
              <div className="flex items-center space-x-2">
                <VerifiedIcon color="#049BC0" bordercolor="#DBF4FA" />
                <p>Value and history report</p>
              </div>
              <div className="flex items-center space-x-2">
                <VerifiedIcon color="#049BC0" bordercolor="#DBF4FA" />
                <p>E-sign the bill of sale</p>
              </div>
              <div className="flex items-center space-x-2">
                <VerifiedIcon color="#049BC0" bordercolor="#DBF4FA" />
                <p>Instant payment</p>
              </div>
            </div>
          </div>
          <LicensePlateAndVinSection />
        </section>
        <section className="w-full h-full bg-[#2F3B55] py-10">
          <h2 className="text-3xl sm:text-[50px] font-bold text-center text-white pt-3">
            Why PrivateAuto?
          </h2>
          <div className="sm:flex justify-center sm:space-x-[67px] text-white text-center pt-9 pb-3 space-y-10 sm:space-y-0">
            <div className="w-auto">
              <div className="sm:h-[85px]">
                <Image
                  width={81}
                  height={78}
                  src="/assets/verified-users.svg"
                  className="w-[64px] sm:w-[81px] mx-auto"
                  alt="verified users"
                />
              </div>
              <p className="text-[22px] sm:text-2xl font-bold pt-4">
                Verified users
              </p>
              <p className="w-[198px] text-sm sm:text-base mx-auto">
                Driver&apos;s license verification. Avoid scams.
              </p>
            </div>
            <div>
              <div className="sm:h-[85px]">
                <Image
                  width={67}
                  height={85}
                  src="/assets/automated.svg"
                  className="w-[50px] sm:w-[67px] mx-auto"
                  alt="fully automated"
                />
              </div>
              <p className="text-[22px] sm:text-2xl font-bold pt-4">
                Fully automated
              </p>
              <p className="w-[198px] text-sm sm:text-base mx-auto">
                100% tech driven. Close the deal on your own.
              </p>
            </div>
            <div>
              <div className="sm:h-[85px]">
                <Image
                  width={85}
                  height={80}
                  src="/assets/instant-transfer.svg"
                  className="w-[70px] sm:w-[85px]  mx-auto"
                  alt="verified users"
                />
              </div>
              <p className="text-[22px] sm:text-2xl font-bold pt-4">
                Instant transfers
              </p>
              <p className="w-[198px] text-sm sm:text-base mx-auto">
                Verified funds. Securely transfer money anytime.
              </p>
            </div>
          </div>
        </section>
        <div className="w-full">
          <DealStartNowSection />
          <div className="w-full h-[330px] block sm:hidden bg-white"></div>
        </div>
        {data != null && <VehicleSearch data={data} />}
      </div>
      <GoogleReviewsScript />
    </>
  );
};

export default HomeNonStrapiPage;
